<template>
  <div>
    <h3 class="flix-html-h3">{{ $t('message.password') }}</h3>
    <div class="flix-form-group">
      <input spellcheck="false" type="text" v-model="password" class="flix-form-control" :placeholder="$t('message.add', {name: $t('message.password')})"/>
    </div>
    <small class="flix-html-small flix-text-info">
      <flixIcon :id="'info-sign'" />&nbsp;
      <span v-if="!password">{{ $t('message.optional') }}</span>
      <span v-else>{{ $tc('message.passwordProtected', 1, {name: $tc('message.calendar', 1)}) }}</span>
    </small>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object,
    callback: Function
  },
  data () {
    return {
      password: this.data.password
    }
  },
  watch: {
    password (val) { this.callback(val.trim()) }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
